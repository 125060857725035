<template>
  <div>
    <div class="nav-wrap">
    <div class="mask" v-if="scrollStart"></div>
    <div class="help-nav" ref="headertab" @scroll="orderScroll">
      <ul class="nav-list" ref="tabitem">
        <li :class="isActive == index ? 'ele active' : 'ele'" v-for="(item, index) in navList" :key="index" @click="getTab(index, item.path ,$event)">{{ item.name }}</li>
      </ul>
    </div>
  </div>
    <service-icon></service-icon>
    <router-view></router-view>
  </div>
</template>

<script>
import Service from '@/components/help/service'
import { mapState } from 'vuex'
export default {
  name: 'help',
  components: {
    'service-icon': Service
  },
  data () {
    return {
      isActive: 0,
      lastItemIndex: 0,
      scrollStart: true
    }
  },
  created () {
    this.sLeft = localStorage.sLeft ? localStorage.sLeft : 0
    this.changeNav(this.$route.path)
  },
  mounted () {
    document.title = '帮助中心'
    if (window.name === '') {
      console.log('初次加载')
      localStorage.removeItem('sLeft')
      this.$refs.headertab.scrollLeft = 0
      window.name = 'isReload'
    } else if (window.name === 'isReload') {
      this.$refs.headertab.scrollLeft = this.sLeft
    }
  },
  computed: {
    ...mapState({
      navList: state => state.help.navList
    })
  },
  methods: {
    // 监听导航是否滑动
    orderScroll () {
      const x = this.$refs.headertab.scrollLeft
      if (x > 0) {
        this.scrollStart = false
      } else {
        this.scrollStart = true
      }
    },
    // 导航点击事件
    getTab (index, url, e) {
      const tab = this.$refs.headertab
      const boxWidth = tab.offsetWidth
      const elList = e.target
      const elWidth = elList.offsetWidth
      const itemWidth = elList.offsetWidth
      // 上一个选中的 item 下标 和 当前选中 item 下标
      this.lastItemIndex = this.isActive
      this.$router.replace(url)
      this.isActive = index
      if (elWidth <= boxWidth / 3) {
        // 获取当前容器的 scrollLeft 并计算接下去要移动的目标值
        const distance = tab.scrollLeft
        const total = index * itemWidth - Math.floor(boxWidth / 3)
        // 上述两者的差值即为要移动的距离，将其细分为小块，确保为正值
        let step = (distance - total) / 50
        if (step < 0) step = -step
        this.moveSlow(distance, total, step)
      }
    },
    // 导航点击滚动动画
    moveSlow (distance, total, step) {
      // 正向滚动 和 反向滚动
      if (this.lastItemIndex < this.isActive) {
        // 每隔1毫秒移动一小段距离，直到移动至目标至为止，反之亦然
        if (distance < total) {
          distance += step
          this.$refs.headertab.scrollLeft = distance
          setTimeout(() => {
            this.moveSlow(distance, total, step)
          }, 1)
          localStorage.sLeft = distance
        } else {
          this.$refs.headertab.scrollLeft = total
          localStorage.sLeft = total
        }
      } else if (this.lastItemIndex > this.isActive) {
        if (distance > total) {
          distance -= step
          this.$refs.headertab.scrollLeft = distance
          localStorage.sLeft = distance
          setTimeout(() => {
            this.moveSlow(distance, total, step)
          }, 1)
        } else {
          this.$refs.headertab.scrollLeft = total
          localStorage.sLeft = total
        }
      }
    },
    // 导航滚动位置
    changeNav (path) {
      switch (path) {
        case '/help/common' :
          this.isActive = 0
          break
        case '/help/recharge' :
          this.isActive = 1
          break
        case '/help/net' :
          this.isActive = 2
          break
        case '/help/flow' :
          this.isActive = 3
          break
        case '/help/real' :
          this.isActive = 4
          break
        case '/help/device' :
          this.isActive = 5
          break
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.changeNav(to.path)
    next()
  }
}
</script>

<style lang="scss" scoped>
  .nav-wrap{
    position: relative;
    .mask {
      width: 4.5rem;
      height: 100%;
      position: absolute;
      right: 0;
      top:0;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 160.56%);
      z-index: 10;
      }
  }

  .help-nav{
    height: 3.125rem;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    transition: all 800ms linear;
    position: relative;

    &::-webkit-scrollbar{
      display: none;
    }

    .nav-list{
      display: flex;
      height: 3.125rem;
      line-height: 3.125rem;
      align-items: center;
      user-select: none;
      padding-left: 0.4rem;
      margin: 0;

      .ele {
        height: 100%;
        font-size: 0.875rem;
        padding: 0 0.6rem;
        white-space: nowrap;
        list-style: none;
        position: relative;
        width: 3.5rem;
        text-align: center;
        &::after{

          height: 0.25rem;
          position: absolute;
          left: 0.6rem;
          right: 0.6rem;
          bottom: 0;
          background-color: #0173F7;
        }
        &.active{
          &::after{
            content: '';
          }
        }
      }
    }
  }
</style>
