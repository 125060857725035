<template>
  <div class="btn-service" @click="service()">
    <svg-icon icon-class="icon_service" />
  </div>
</template>

<script>
export default {
  name: 'service',
  methods: {
    service () {
      console.log('help@@@1')
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-service{
    position: fixed;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 3rem;
    background-color: rgba(1, 130, 249, 0.8);
    right: 0.625rem;
    bottom: 3.5rem;
    z-index: 100;
    .svg-icon{
      width: 2.25rem;
      height: 2.25rem;
      position: absolute;
      left: 50%;
      top:50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
